<template>
  <div id="Home"></div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { dom } from "quasar";
export default {
  methods: {
    ...mapActions("Tickers", [
      "getTickersType",
      "updateQuotes",
      "getCurrencyExchangeRateByDate"
    ]),
    ...mapMutations("Shared", ["setWorkareaHeight"])
  },
  async created() {
    await this.getTickersType();
    this.$q.loading.show({
      message:
        'Atualizando cotações.<br/><span class="text-primary">Aguarde...</span>'
    });
    try {
      await this.getCurrencyExchangeRateByDate();
      await this.updateQuotes();
      this.$q.loading.hide();
    } catch (error) {
      console.error(error.message);
      this.$q.loading.hide();
    }
  },
  async mounted() {
    const { height } = dom;
    const element = document.getElementById("Home");
    const workareaHeight = `${height(element.parentNode.parentNode) - 130}px`;
    this.setWorkareaHeight(workareaHeight);
  }
};
</script>
